// import { store } from 'vuex'
import jwtDefaultConfig from './jwtDefaultConfig'

const envHost = https://teis-eulen-qa.westeurope.cloudapp.azure.com/ || 'https://teis-eulen-qa.westeurope.cloudapp.azure.com/'

export default class JwtService {
  // Will be used by this service for making API calls
  axiosIns = null

  // jwtConfig <= Will be used by this service
  jwtConfig = { ...jwtDefaultConfig }

  // For Refreshing Token
  isAlreadyFetchingAccessToken = false

  // For Refreshing Token
  subscribers = []

  constructor(axiosIns, jwtOverrideConfig) {
    this.axiosIns = axiosIns
    this.jwtConfig = { ...this.jwtConfig, ...jwtOverrideConfig }

    // Request Interceptor
    this.axiosIns.interceptors.request.use(
      config => {
        const headersData = {
          'Access-Control-Allow-Credentials': 'true',
          'Access-Control-Allow-Headers': '*',
          'Access-Control-Allow-Origin': `${envHost.slice(0, (envHost.length - 1))}`,
          'Access-Control-Allow-Methods': config.method,
          'Access-Control-Expose-Headers': '*',
          'Access-Control-Max-Age': 600,
          'Permissions-Policy': 'accelerometer=(),autoplay=(),camera=(),display-capture=(),encrypted-media=(),fullscreen=(),gamepad=(),geolocation=(),gyroscope=(),magnetometer=(),microphone=(),midi=(),payment=(),picture-in-picture=(),publickey-credentials-get=(),sync-xhr=(self),usb=(),screen-wake-lock=(),web-share=(),xr-spatial-tracking=()',
          'Referrer-Policy': 'same-origin',
          'X-Content-Type-Options': 'nosniff',
          'X-Permitted-Cross-Domain-Policies': 'master-only',
          'X-Frame-Options': 'SAMEORIGIN',
          'Content-Security-Policy': `frame-src ${envHost.slice(0, (envHost.length - 1))}`,
          'Cross-Origin-Embedder-Policy': 'require-corp',
          'Cross-Origin-Opener-Policy': 'same-origin',
          'Cross-Origin-Resource-Policy': 'same-origin',
          'Strict-Transport-Security': 'max-age=31536000 ; includeSubDomains',
          'Powered-By': '---',
          'X-Powered-By': '---',
          Server: '---',
        }
        const headerKeys = Object.keys(headersData)
        const headerValues = Object.values(headersData)
        headerKeys.forEach((propName, index) => {
          // eslint-disable-next-line no-param-reassign
          config.headers[propName] = headerValues[index]
        })

        // Get token from localStorage
        const accessToken = config.url === this.jwtConfig.loginAiopsEndpoint || config.url === this.jwtConfig.refreshEndpoint ? null : this.getToken()

        if (config.url === this.jwtConfig.loginAiopsEndpoint || config.url === this.jwtConfig.refreshEndpoint) {
          // eslint-disable-next-line no-param-reassign
          config.headers[this.jwtConfig.contentType] = this.jwtConfig.content_type
        }

        // If token is present add it to request's Authorization Header
        if (accessToken) {
          // eslint-disable-next-line no-param-reassign
          config.headers.Authorization = `${this.jwtConfig.tokenType} ${accessToken}`
        }
        return config
      },
      error => Promise.reject(error),
    )

    // Add request/response interceptor
    this.axiosIns.interceptors.response.use(
      response => response,
      error => {
        const { response } = error
        return Promise.reject(response)
      },
    )
  }

  //* Set & Get Token

  getToken() {
    return sessionStorage.getItem(this.jwtConfig.storageTokenKeyName)
  }

  getRefreshToken() {
    return sessionStorage.getItem(this.jwtConfig.storageRefreshTokenKeyName)
  }

  setToken(value) {
    sessionStorage.setItem(this.jwtConfig.storageTokenKeyName, value)
  }

  setRefreshToken(value) {
    sessionStorage.setItem(this.jwtConfig.storageRefreshTokenKeyName, value)
  }

  //* Login

  login(...args) {
    return this.axiosIns.post(this.jwtConfig.loginAiopsEndpoint, ...args)
  }

  //* Refresh token

  refreshToken(...args) {
    return this.axiosIns.post(this.jwtConfig.refreshEndpoint, ...args)
  }

  //* Home

  getTickets(...args) {
    console.log(args)
    return this.axiosIns.get(`${this.jwtConfig.ticketsAiops}/?${args}`)
  }

  getModelTree() {
    return this.axiosIns.get(this.jwtConfig.modelsTree)
  }

  getCategoriesTree() {
    return this.axiosIns.get(this.jwtConfig.categoryTree)
  }

  getPredictionModel(...args) {
    return this.axiosIns.post(`${this.jwtConfig.predictionModel}?modelName=${args[0].modelNameFormated}`, args[0].msgToPredict)
  }

  applyArea(...args) {
    return this.axiosIns.post(`${this.jwtConfig.applyArea}`, ...args)
  }

  getTicketDetail(...args) {
    return this.axiosIns.get(`${this.jwtConfig.ticketDetail}/${args[0].id}`)
  }

  getSimilarTickets(...args) {
    return this.axiosIns.get(`${this.jwtConfig.similarTickets}/${args[0].idTicket}/${args[0].numberSimilars}`)
  }

  getTicketVectors(...args) {
    return this.axiosIns.get(`${this.jwtConfig.vectorTicket}/${args[0]}`)
  }

  //* Model Manager

  getModelManager(...args) {
    return this.axiosIns.get(`${this.jwtConfig.modelManager}/${args[0].area}`)
  }

  getModelSubcategory(modelName, modelClass) {
    return this.axiosIns.get(`${this.jwtConfig.modelSubcategory}?parentModelName=${modelName}&parentModelClass=${modelClass}`)
  }

  modifyModel(...args) {
    return this.axiosIns.post(`${this.jwtConfig.modelclass}`, ...args)
  }

  switchModel(modelName) {
    return this.axiosIns.post(`${this.jwtConfig.switchModel}?modelName=${modelName}`)
  }

  getAssignmentGroup() {
    return this.axiosIns.get(this.jwtConfig.assignmentGroup)
  }

  //* Data Source

  configService(...args) {
    return this.axiosIns.post(`${this.jwtConfig.configHost}`, ...args)
  }

  getConfigList() {
    return this.axiosIns.get(`${this.jwtConfig.configList}`)
  }

  //* Users

  getUsers() {
    return this.axiosIns.get(`${this.jwtConfig.users}`)
  }

  createUsers(...arg) {
    return this.axiosIns.post(`${this.jwtConfig.users}`, ...arg)
  }

  getUserById(id) {
    return this.axiosIns.get(`${this.jwtConfig.users}/${id}`)
  }

  updateUser(...arg) {
    return this.axiosIns.put(`${this.jwtConfig.users}/${arg[0].id}`, arg[0].payloadUser)
  }

  findUsers(query) {
    if (query) {
      return this.axiosIns.get(`${this.jwtConfig.users}/find${query}`)
    }
    return this.axiosIns.get(`${this.jwtConfig.users}/find`)
  }

  getPasswordRule() {
    return this.axiosIns.get(`${this.jwtConfig.passwordRule}`)
  }

  //* Roles

  getGroups() {
    return this.axiosIns.get(`${this.jwtConfig.groups}`)
  }

  getMembersGroups(id) {
    return this.axiosIns.get(`${this.jwtConfig.groups}/${id}/members`)
  }

  //* Knowledge base

  getKnowledgeBaseList() {
    return this.axiosIns.get(this.jwtConfig.knowledgeBase)
  }

  createKnowledgeBase(...args) {
    return this.axiosIns.post(`${this.jwtConfig.knowledgeBase}`, ...args)
  }

  updateKnowledgeBase(...arg) {
    return this.axiosIns.put(`${this.jwtConfig.knowledgeBase}/${arg[0].id}`, arg[0].payloadKnowledgeBase)
  }

  deleteKnowledgeBase(...arg) {
    return this.axiosIns.delete(`${this.jwtConfig.knowledgeBase}/${arg[0].id}`)
  }

  //* Other

  register(...args) {
    return this.axiosIns.post(this.jwtConfig.registerEndpoint, ...args)
  }

  onAccessTokenFetched(accessToken) {
    this.subscribers = this.subscribers.filter(callback => callback(accessToken))
  }

  addSubscriber(callback) {
    this.subscribers.push(callback)
  }
}
